import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mr: 1,
};

export default function AppFooter() {
  const { t } = useTranslation();
  return (
    <Typography
      component="footer"
      sx={{
        display: "fixed",
        bottom: 0,
        right: 0,
        width: "100%",
        bgcolor: "#1B1534",
      }}
    >
      <Container>
        <Grid container spacing={3} display="flex" flexDirection="column">
          <Grid item>
            {/* <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                  <Typography color="white" variant="caption" >
                  CARLA - Customer Access to Region Latin America
                  </Typography>
              </Grid>
              <Grid item >
                <Grid container>
                  <Grid item sx={{ display: 'flex' }}>
                    <Box component="a" href="https://twitter.com/siemens_energy" sx={iconStyle}>
                      <img
                        src={Twitter}
                        alt="Twitter"
                      />
                    </Box>
                    <Box component="a" href="https://de.linkedin.com/showcase/siemens-energy" sx={iconStyle}>
                      <img
                        src={LinkedIn}
                        alt="LinkedIn"
                      />
                    </Box>
                    <Box component="a" href="https://www.facebook.com/SiemensEnergy/" sx={iconStyle}>
                      <img
                        src={Facebook}
                        alt="Facebook"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Link
                  underline="hover"
                  color="white"
                  href="https://www.siemens-energy.com/us/en.html"
                >
                  <Typography variant="caption">
                    siemens-energy.com Global Website
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Grid container spacing={3}>
                  <Grid item>
                    <Link
                      underline="hover"
                      color="white"
                      href="https://www.siemens-energy.com/global/en/general/corporate-information.html"
                    >
                      <Typography variant="caption">
                      {t("corp_info")}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      underline="hover"
                      color="white"
                      href="https://www.siemens-energy.com/global/en/general/privacy-notice.html"
                    >
                      <Typography variant="caption">{t("privacy_notice")}</Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      underline="hover"
                      color="white"
                      href="https://www.siemens-energy.com/global/en/general/terms-of-use.html"
                    >
                      <Typography variant="caption">{t("terms")}</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider color="white" sx={{ mt: 5 }} />
            </Grid>
          </Grid>
          <Grid item sx={{mb:2}}>
            <Typography variant="caption" color="#ada9c2" >
              {t("copyright")}
              <br />© Siemens Energy, 2020 - 2021
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
