import { Button, Box, Typography, Grid, Link } from "@mui/material";
import bg from "../assets/images/dotsphere.png";
import bgMobile from "../assets/images/se_mobile.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../App.css";
import LanguageSelect from "../component/langSelect";
// import {makeStyles} from "@mui/material/styles";

export default function Splash(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const styles = {
    select: {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
        backgroundColor: "#1B1534", 
        textColor: "white"
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
        borderWidth: '0.15rem',
        backgroundColor: "#1B1534"
      },
      '&:select .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
        borderWidth: '0.15rem',
        backgroundColor: "#1B1534"
      },
    },
  };

  const buttonStyles = {
    borderRadius: 8,
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "white",
    },
  }

  const mobileButtonStyles = {
    borderRadius: 8,
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "white",
    },
    mb: 2,
    width: "90%",
    mt: 3,
  }

  return (
    <>
      {mobile ? (
        <Box
          //  className={classes.splashHome}
          style={{
            backgroundImage: `url(${bgMobile})`,
            backgroundColor: "#150d30",
            color: "white",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top 100px left 5px",
            height: "95vh",

          }}
        >
          <Grid container justifyContent="end">
            <Grid item sx={{mt:4, mr:2}}>
            <LanguageSelect  language={props.language} setLanguage={props.setLanguage}/>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            padding="3"
            spacing="2"
            height="80%"
          >
            <Box sx={{ ml: 2 }}>
              <Grid item>
                <Typography variant="h4">{t("welcome_to")}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2">e-CARLA</Typography>
              </Grid>
              <Grid item>
                <Typography variant="p" color="#23c0f8">
                  {t("carla")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={mobileButtonStyles}
                  onClick={() => {
                    navigate("/survey/");
                  }}
                >
                  {t("take_survey")}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      ) : (
        <Box
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#150d30",
            height: "91.9vh",
            color: "#f5f5f5",
            overflow:"hidden"
          }}
        >
          <Grid container justifyContent="end">
            <Grid item sx={{mt:4, mr:2}}>
            <LanguageSelect language={props.language} setLanguage={props.setLanguage}/>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            padding="3"
            spacing="2"
            height="80% "
          >
            <Box sx={{ ml: 3 }}>
              <Grid item>
                <Typography variant="h3">{t("welcome_to")}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h1">e-CARLA</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" color="#23c0f8">
                  {t("carla")}
                </Typography>
              </Grid>
              <br />
              <br />
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  sx={buttonStyles}
                  onClick={() => {
                    navigate("/survey/");
                  }}
                >
                  {t("take_survey")}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
}
