import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Grid, Link } from "@mui/material";
import { useAuth } from "../utils/UserContext";
import AssignDialog from "./assignDialog";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import UnassignDialog from "./unassignConfirmDialog";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const admins = [
  {
    firstName: "Fadi",
    lastName: "Abou Ghantous",
    email: "fadi.ghantous@siemens-energy.com",
  },
  {
    firstName: "Brenda",
    lastName: "Mendez",
    email: "brenda.mendez@siemens-energy.com",
  },
  {
    firstName: "Grace",
    lastName: "Alvarez",
    email: "grace.alvarez@siemens-energy.com",
  },
  {
    firstName: "Mike",
    lastName: "Klinkhammer",
    email: "mike.klinkhammer@siemens-energy.com",
  },
  {
    firstName: "Joe",
    lastName: "Matos",
    email: "joe.matos@siemens-energy.com",
  },
  {
    firstName: "Alejandro",
    lastName: "Herrera",
    email: "alejandro.herrera@siemens-energy.com",
  },
  {
    firstName: "Ferdinand",
    lastName: "Velez",
    email: "ferdinand.velez@siemens-energy.com",
  },
  {
    firstName: " Joseph",
    lastName: "Dodd Rodriguez",
    email: "joseph.dodd@siemens-energy.com",
  },
  {
    firstName: " Ute",
    lastName: "Baier",
    email: "ute.baier@siemens-energy.com",
  },
  {
    firstName: "Alejandro",
    lastName: "Neira",
    email: "alejandro.neira@siemens-energy.com",
  },
  {
    firstName: "Christine",
    lastName: "Stevens",
    email: "christine.stevens.ext@siemens-energy.com",
  },
];

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Ticket(props) {
  const [expanded, setExpanded] = React.useState(false);
  const { currentUser } = useAuth();

  const ticket = props.ticket;

  const color =
    ticket.positive === "true" || ticket.positive === true
      ? "#b0ffc2"
      : "#ffb1b1";

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function getAdmin(email) {
    return admins.find((obj) => {
      return obj.email === email;
    });
  }

  function showName() {
    if (ticket.resolving) {
      const adminInfo = getAdmin(ticket.admin_name.replace(" ", "@"));
      const adminName = adminInfo["firstName"] + " " + adminInfo["lastName"];

      return (
        <Grid container direction="row" sx={{mt:3}}>
          <Grid item>
            <Typography variant="subtitle" color="text.secondary">
              Assigned to {adminName} on {ticket.time_updated}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return null; 
  }

  function marks() {
    if (ticket.resolving) {
      return (
            <UnassignDialog
              sessionId={props.sessionId}
              setLoading={props.setLoading}
              ticket={ticket}
              currentUser={currentUser}
            ></UnassignDialog>
      );
    } else {
      return (
        <AssignDialog
          sessionId={props.sessionId}
          setLoading={props.setLoading}
          ticket={ticket}
          currentUser={currentUser}
        />
      );
    }
  }

  return (
    <Card sx={{ minWidth: "300px", backgroundColor: color }}>
      <CardContent>
        <Grid container direction="row" justifyContent={"space-between"}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="body2">Name:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {ticket.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="body2">Company:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {ticket.company}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="body2">Unit:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {ticket.unit}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="body2">Email:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {ticket.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="body2">Ticket Created:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  {ticket.time_created}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="body2">Ticket ID:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                <Link href={`/admin/${ ticket.ticketid}`}>  {ticket.ticketid} </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Typography sx={{ mr: 1 }} variant="body2">
                Feedback type:
              </Typography>
              {ticket.positive === true || ticket.positive === "true" ? (
                <SentimentSatisfiedAltIcon />
              ) : (
                <SentimentVeryDissatisfiedIcon />
              )}
            </Grid>
            {ticket.resolving ? (
              <Typography variant="body2">In Progress</Typography>
            ) : (
              <Typography variant="body2">Open</Typography>
            )}
          </Grid>
        </Grid>
        { showName()}
      </CardContent>
      <CardActions disableSpacing>
        <Grid container justifyContent="space-between">
          {marks()}
          {expanded ? (
            <Button onClick={handleExpandClick} endIcon={<ExpandLessIcon />}>
              View Feedback
            </Button>
          ) : (
            <Button onClick={handleExpandClick} endIcon={<ExpandMoreIcon />}>
              View Feedback
            </Button>
          )}
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography component={"span"}>{ticket.feedback}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
