export const resources = {
  en: {
    translation: {
      welcome_to: "Welcome to",
      return_to_home: "Return home",
      carla: "Executive Customer Access to Region Latin America",
      take_survey: "Leave your feedback",
      thanks: "Thanks",
      take_another: "Leave more feedback",
      name: "Full Name",
      email: "Email",
      company: "Company",
      q1: "Which Frame/Unit are you providing feedback for?",
      q2: "Your feedback is: ",
      q3: "What is your feedback?",
      submit: "Submit",
      positive: "Positive",
      negative: "Negative",
      write_here: "Write here",
      survey_intro: "Enter your feedback here",
      thanks_positiveFeedback:
        "We are extremely grateful that you took the time to send us your feedback. Your positive experience reflects our Customer Satisfaction priority. ",
      thanks_negativeFeedback:
        "Thanks for bringing this matter to our attention. We're very sorry that you had a negative experience. You will be contacted by one of our managers.",
      copyright: "Siemens Energy is a trademark licensed by Siemens AG.",
      privacy_notice_text:
        "By submitting this survey you are acknowledging the privacy notice",
      corp_info: "Corporate Information",
      privacy_notice: "Privacy Notice",
      terms: "Terms of Use",
      privacy_link:
        "https://www.siemens-energy.com/global/en/general/privacy-notice.html",
      language: "Language",
      unit: "Frame/Unit",
      error_message: "This is a required field", 
      email_error: "Please use a valid email."
    },
  },
  es: {
    translation: {
      welcome_to: "Bienvenido a",
      return_to_home: "Regresar a inicio",
      carla: "Executive Customer Access to Region Latin America",
      take_survey: "Deje sus comentarios",
      thanks: "Gracias",
      take_another: "Vuelva a darnos mas comentarios",
      name: "Nombre y Apellido",
      email: "Email",
      company: "Empresa",
      q1: "¿Para qué frame/unidad está proporcionando comentarios?",
      q2: "Su comentario es: ",
      q3: "Por favor deje sus comentarios:",
      submit: "Enviar",
      positive: "Positivo",
      negative: "Negativo",
      write_here: "Escriba aquí",
      survey_intro: "Escriba sus comentarios aquí",
      thanks_negativeFeedback:
        " Gracias por informarnos sobre este asunto. Lamentamos mucho que haya tenido una experiencia negativa. Nos gustaría recopilar más información sobre su experiencia; por ello, uno de nuestros responsables, se pondrá en contacto con usted. ",
      thanks_positiveFeedback:
        "Estamos muy agradecidos de que se haya tomado el tiempo de enviarnos sus comentarios. Su experiencia positiva refleja nuestra prioridad de satisfacer a nuestros clientes.",
      copyright:
        "Siemens Energy es una marca comercial registrada bajo licencia de Siemens AG.",
      corp_info: "Información Corporativa",
      privacy_notice_text:
        "Al enviar esta encuesta está aceptando el aviso de privacidad",
      privacy_notice: "Política de Privacidad de Datos",
      terms: "Condiciones de Uso",
      privacy_link:
        "https://www.siemens-energy.com/mx/es/general/politica-de-privacidad.html",
      language: "Idioma",
      unit: "Frame/Unidad",
      error_message: "Por favor rellene este campo.", 
      email_error: "Por favor introduzca una dirección de correo electrónico válida."
    },
  },
  pt: {
    translation: {
      welcome_to: "Bem-vindo a",
      return_to_home: "Retornar ao menu",
      carla: "Executive Customer Access to Region Latin America",
      take_survey: "Responder a pesquisa",
      thanks: "Obrigado",
      take_another: "Responder outro",
      name: "Nome e Sobrenome",
      email: "Email",
      company: "Empresa",
      q1: "Para qual estrutura/unidade de negócio você está fornecendo o feedback?",
      q2: "Seu feedback é: ",
      q3: "Qual é o seu feedback?",
      submit: "Enviar",
      positive: "Positivo",
      negative: "Negativo",
      write_here: "Escreva aqui",
      privacy_notice_text:
        "Ao enviar esta pesquisa, você está aceitando o aviso de privacidade",
      survey_intro: "Introdução da pesquisa",
      thanks_negativeFeedback:
        "Obrigado por trazer este tema à nossa atenção. Lamentamos que você tenha tido uma experiência negativa. Gostaríamos de coletar mais detalhes sobre a sua experiência e, portanto, você será contato por um dos nossos gerentes. ",
      thanks_positiveFeedback:
        "Agradecemos a sua dedicação e gentileza por nos enviar os seus comentários. Sua experiência positiva reflete nossa prioridade com a satisfação dos clientes.",
      copyright:
        "Siemens Energy é uma marca registrada licenciada pela Siemens AG.",
      privacy_notice: "Notificação de privacidade",
      corp_info: " Informação Corporativa",
      terms: "Termos de Uso",
      privacy_link:
        "https://www.siemens-energy.com/br/portugues/geral/notificacao-de-privacidade-de-dados.html",
      language: "Língua",
      unit: "Estrutura/Unidade",
      error_message: "error message pt", 
      email_error: "email error pt"
    },
  },
};
