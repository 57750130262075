import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ListItemText, ListSubheader, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function UnitAutoComplete(props) {
  const unitName = props.unitName;
  const setUnitName = props.setUnitName;
  const { t } = useTranslation();

  const handleChange = (event, value) => {
    setUnitName(value);

  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={units}
      disableCloseOnSelect
      onChange={handleChange}
      getOptionLabel={(option) => option}
      renderOption={
        (props, option, { selected }) => {
          if (Number.isInteger(parseInt(option))) {
            return (
              <ListSubheader key={option}> {unitTitles[option]} </ListSubheader>
            );
          } else {
            return (
                <li {...props}>
                <Checkbox
                  checked={selected}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                />
                {option}
                </li>
            );
          }
        }
      }
      renderInput={(params) => (
        <TextField {...params} label={t("unit")} placeholder={t("unit")} />
      )}
    />
  );
}

const unitTitles = [
  "Aeroderivative Gas Turbine",
  "Generator",
  "Medium Gas Turbine",
  "Reciprocating Compressors",
  "Small Gas Turbine",
  "Turbo Compressor",
  "Combustion Turbine",
  "Steam Turbine",
  "Control System", 
  "Other",
];

const units = [
  "0",
  "SGT-050",
  "SGT-A05",
  "SGT-A20",
  "SGT-A35",
  "SGT-A45",
  "SGT-A65",
  "Vectra",
  "1",
  "Generator",
  "Generator 2-pole",
  "Generator 4-pole",
  "2-102X158",
  "2-102X245",
  "2-98X204",
  "4-114X230",
  "AEROPAC",
  "BDAX 8.335",
  "BDAX 8.365ERH",
  "BDAX 8.445ER",
  "BDAX 82.445ERH",
  "FTHDD 560/69-2",
  "MODULAR",
  "SGEN5-1000A",
  "SGEN5-100A",
  "SGEN5-100A-2P  ||  TLRI 115/36 DTC",
  "SGEN6-1000A  ||  S2R2-180 60HZ  ||  AEROPAC II",
  "SGEN6-1000A  ||  S2R2-196 60HZ  ||  AEROPAC II",
  "SGEN6-1000A  ||  S2R2-216 60HZ  ||  AEROPAC II",
  "SGEN6-2000H",
  "SGEN6-2000H  ||  S4R3-180 60HZ",
  "THDD 170/64-15",
  "THFF180/64-18",
  "THRI 86/27-36",
  "TLRI 100/28-36",
  "TLRI 100/30-36  ||  SGEN6-100A-2P",
  "TLRI 108/36",
  "TLRI 108/36-36",
  "TLRI 108/46-36",
  "TLRI 115/41",
  "TLRI 115/47",
  "TLRI 115/52",
  "TLRI 93/33-36",
  "WESTAC",
  "2",
  "SGT-500",
  "SGT-600",
  "SGT-700",
  "SGT-750",
  "SGT-800",
  "3",
  "Gas Engine",
  "High Speed Separable",
  "Heavy Process",
  "Hyper Process",
  "Light Process",
  "4",
  "SGT-100",
  "SGT-200",
  "SGT-300",
  "SGT-400",
  "TA",
  "TB",
  "TE500",
  "TF1200",
  "5",
  "B Line Barrels",
  "B Line Super Barrels",
  "Centregral Line Turbocompressor",
  "Cooper Process Turbocompressor",
  "CVS Line",
  "Datum Radially Split Design",
  "Delaval",
  "Integral Gear ISOPAC",
  "M Line",
  "MXX Line Horizontally Split Design",
  "MXXX Line Vertically Split Design",
  "RFA/RFBB",
  "STC-GV",
  "STC-SH",
  "STC-SV",
  "Axi L Line & H Line",
  "Axial",
  "Blower",
  "CBF Line",
  "CDP Line",
  "Centac",
  "CVM Line",
  "Datum Axially Split Design",
  "Datum Pipeline Booster",
  "FCC Expander",
  "H Line",
  "Motor",
  "P Line",
  "STC-GC",
  "STC-GO",
  "STC-GT",
  "STC-GVT",
  "STC-PVK",
  "STC-SP",
  "STC-SR",
  "STC-SX",
  "6",
  "SGT-1000F || V64.3A",
  "SGT5-2000E || V94.2",
  "SGT5-4000 || V94.3A",
  "SGT6-2000E  ||  V84.2",
  "SGT6-3000E  ||  W501D5A",
  "SGT6-4000F  ||  V84.3A",
  "SGT6-5000F  ||  W501F",
  "SGT6-6000G  ||  W501G",
  "SGT6-8000H",
  "V94.3",
  "W501D5",
  "7",
  "1426RT2",
  "2286RT2",
  "4408ET3",
  "SST-3000  ||  HE",
  "SST-4000  ||  DN",
  "SST-5000  ||  KN",
  "SST-8000  ||  SN",
  "SST-9000  ||  SN",
  "TG762",
  "8",
  "SPPA-T3000",
  "9",
  "Other",
];
