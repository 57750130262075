import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SELogo from "../assets/images/logo.svg";
import BackButton from "./backbutton";
import { useLocation } from "react-router-dom";
import { IconButton, Stack } from "@mui/material";
import Carla from "../assets/images/ecarla_title.png";

const noBackButtonList = ["/home", "/", "/register"];

export default function NavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="fixed" sx={{ bgcolor: "#1B1534" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box width="100%" padding={1}>
            <Stack direction="row" spacing={5} alignItems="center" justifyContent="space-between">
              {!noBackButtonList.includes(useLocation().pathname) ? (
                <BackButton />
              ) : null}
              <img height={40} src={SELogo} alt="Siemens Energy" />
              <img height={60} src={Carla} alt="Carla Icon" />
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" sx={{ justifyContent: "space-between" }}>
        <Box >
          <img height={40} src={SELogo} alt="Siemens Energy" />
        </Box>
      </Toolbar>
    </Box>
  );
}
