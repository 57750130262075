import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import {
  Typography,
  FormControl,
  FormControlLabel,
  TextField,
  Box,
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  Radio,
  RadioGroup,
  Link,
} from "@mui/material";
import LanguageSelect from "../component/langSelect";
import UnitSelect from "../component/unitSelect";
import UnitAutoComplete from "../component/unitAutoComplete";

export default function Survey(props) {
  const [submitted, setSubmitted] = useState(false);
  const [msg, setMsg] = useState("");
  const [answers, setAnswers] = useState(["", ""]);
  // const [unit, setUnit] = useState("");
  const [feedback, setFeedback] = useState("");
  const [positive, setPositive] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [unitName, setUnitName] = useState([]);
  const [otherUnit, setOtherUnit] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [emailError, setEmailError] = useState(false);

  const userInfo = {};
  const setUser = () => {};

  const styles = {
    select: {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
        textColor: "white",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
        borderWidth: "0.15rem",
      },
    },
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^\S+@\S+\.\S+$/);
  };

  function handleAnswerChange(event, idx) {
    const newAnswers = [...answers];
    newAnswers[idx] = event.target.value;
    setAnswers(newAnswers);
  }

  function handleSubmit() {
    if (validateEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    setSubmitted(true);

    setMsg("");
    if (
      email === "" ||
      company === "" ||
      name === "" ||
      unitName.length < 1 ||
      feedback === "" ||
      positive === null
    ) {
      setMsg("Please fill out all of the fields");
      return;
    }

    const sep = otherUnit.length > 0 ? ", " : "";
    const unit =
      unitName.filter((name) => name != "Other").join(", ") +
      sep +
      "" +
      otherUnit;

    const formData = new FormData();

    formData.append("name", encodeURIComponent(name));
    formData.append("email", email);
    formData.append("company", encodeURIComponent(company));
    formData.append("unit", unit);
    formData.append("positive", positive);
    formData.append("feedback", encodeURIComponent(feedback));
    formData.append("language", props.language);

    const resToSend = {
      unit,
      feedback,
      positive,
      name,
      email,
      company,
      language: props.language,
    };

    localStorage.setItem("name", name);
    localStorage.setItem("company", company);
    localStorage.setItem("email", email);
    // console.log(resToSend);
    props.setPosFeedback(positive);

    setLoading(true);
    axios
      .post(`/upload.php/?session_id=${props.sessionId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setSubmitted(true);
        setLoading(false);
        navigate("/thanks/");
      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        setMsg("Something went wrong");
        console.error(error);
      });
  }

  useEffect(() => {

    const localEmail = localStorage.getItem("email");
    const localName = localStorage.getItem("name"); 
    const localCompany = localStorage.getItem("company")

    if (localEmail) {
      setEmail(localEmail)
    } 
    if (localName) {
      setName(localName);
    }

    if (localCompany) {
      setCompany(localCompany);
    }
  }, []);

  const buttonStyles = {
    color: "white",
    "&:hover": {
      backgroundColor: "#1B1534",
    },
    borderRadius: 8,
    backgroundColor: "#1B1534",
    mb: 2,
  };

  return (
    <Box
      sx={{
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container justifyContent="end">
        <Grid item sx={{ mr: 2 }}>
          <LanguageSelect
            language={props.language}
            setLanguage={props.setLanguage}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Grid container justifyContent="start">
            <Typography sx={{ ml: 3 }} color="black" variant="h5">
              {t("survey_intro")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" padding={2} spacing={4}>
              <Grid item xs={12}>
                <Typography>{t("name")}</Typography>
                <TextField
                  fullWidth
                  id="name"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={submitted && name === ""}
                  helperText={
                    (submitted && name === "") ? t("error_message") : " "
                  }
                />
              </Grid>
              <Grid item>
                <Typography>{t("email")}</Typography>
                <TextField
                  fullWidth
                  id="email"
                  size="small"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={submitted && (emailError || email === "")}
                  helperText={
                    submitted && (emailError || email === "")
                      ? "Por favor introduzca una dirección de correo electrónico válida."
                      : " "
                  }
                />
              </Grid>
              <Grid item>
                <Typography>{t("company")}</Typography>
                <TextField
                  fullWidth
                  id="name"
                  size="small"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  error={submitted && company === ""}
                  helperText={
                    (submitted && company.length < 1) ? t("error_message") : " "
                  }
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" justifyContent={"center"}>
                  <Grid item xs={12}>
                    <Typography>{t("q1")}</Typography>
                    <UnitAutoComplete
                      unitName={unitName}
                      setUnitName={setUnitName}
                    />
                    {/* <UnitSelect unitName={unitName} setUnitName={setUnitName} />*/}
                  </Grid>
                  {unitName && unitName.includes("Other") ? (
                    <Grid item sx={{ mt: 2 }}>
                      <Grid
                        container
                        direction="row"
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography>Other:</Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            fullWidth
                            id="name"
                            size="small"
                            value={otherUnit}
                            onChange={(e) => setOtherUnit(e.target.value)}
                            error={submitted && otherUnit === ""}
                            helperText={
                              submitted && otherUnit === ""
                                ? t("error_message")
                                : " "
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item>
                <Typography>{t("q2")}</Typography>
                <FormControl>
                  <RadioGroup
                    defaultValue={true}
                    onChange={(e) => {
                      if (e.target.value === "true") {
                        setPositive(true);
                      } else {
                        setPositive(false);
                      }
                    }}
                    value={positive}
                    name="radio-buttons-group"
                  >
                    <Grid container direction="row" alignItems="center">
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            sx={{
                              color: "#1B1534",
                              "&.Mui-checked": {
                                color: "#1B1534",
                              },
                            }}
                          />
                        }
                        label={""}
                      />
                      <SentimentSatisfiedAltIcon fontSize="large" />
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            sx={{
                              color: "#1B1534",
                              "&.Mui-checked": {
                                color: "#1B1534",
                              },
                            }}
                          />
                        }
                        label={""}
                      />
                      <SentimentVeryDissatisfiedIcon fontSize="large" />
                    </Grid>
                  </RadioGroup>
                </FormControl>
                {submitted && positive == null ? (
                  <Typography color="red"> {t("error_message")} </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("q3")}</Typography>
                <TextField
                  placeholder={t("write_here")}
                  multiline
                  fullWidth
                  value={feedback}
                  rows={4}
                  // style={{ width: 800 }}
                  onChange={(e) => setFeedback(e.target.value)}
                  error={submitted && feedback === ""}
                            helperText={
                              submitted && feedback === ""
                                ? t("error_message")
                                : " "
                            }
                />
              </Grid>
              <Grid item>
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  href={t("privacy_link")}
                >
                  <Typography>{t("privacy_notice_text")}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {msg ? <>{msg}</> : null}
            {loading ? null : (
              <Grid container direction="column" align="center">
                <Box color="white">
                  <Button
                    variant="contained"
                    color="inherit"
                    sx={buttonStyles}
                    onClick={handleSubmit}
                  >
                    {t("submit")}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
