import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import Survey from "./pages/survey";
import Splash from "./pages/splash";
import Thanks from "./pages/thanks";
import Login from "./admin/login";
import LangSwitch from "./component/switch";
import { Box, Grid, Paper } from "@mui/material";
import NavBar from "./component/navbar";
import LanguageSelect from "./component/langSelect";
import AppFooter from "./component/footer";
import { useAuth } from "./utils/UserContext";
import AdminPanel from "./admin/panel";
import TicketView from "./admin/ticketview";

function App() {
  // hold session id here??
  const [language, setLanguage] = useState("es");
  const [posFeedback, setPosFeedback] = useState(true);
  const location = useLocation();
  const { currentUser, loading } = useAuth();
  const [loadingTickets, setLoadingTickets] = useState(true);
  axios.defaults.baseURL = "https://carla-se.ddns.net/";
  const [sessionId, setSessionId] = useState(null);
  const noNav = ["/thanks", "/thanks/"];
  const [bgColor, setBgColor] = useState("#1B1534");

  function PrivateRoutes() {
    if (currentUser) {
      return <Outlet />;
    } else {
      return <Navigate to={"/login"} />;
    }
  }

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: window.location.href,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    });
  }, [location]);

  useEffect(() => {
    axios.get("/local_client.js").then(function (response) {
      const formData = new FormData();

      formData.append("token", response.data.token);

      axios
        .post(
          "/session_id.php/",
            formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function (res) {
          setSessionId(res.data.session_id);
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  }, []);

  return (
    <>
      {loading ? null : (
        <Box>
          {!noNav.includes(location.pathname) ? <NavBar /> : null}
          <Routes>
            <Route
              path={"/"}
              element={<Splash language={language} setLanguage={setLanguage} />}
            />
            <Route
              path={"/survey"}
              element={
                <Survey
                  sessionId={sessionId}
                  language={language}
                  setLanguage={setLanguage}
                  setPosFeedback={setPosFeedback}
                />
              }
            />
            <Route
              path={"/thanks"}
              element={
                <Thanks
                  language={language}
                  setLanguage={setLanguage}
                  posFeedback={posFeedback}
                />
              }
            />
            <Route path={"/login"} element={<Login />} />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/admin"
                element={
                  <AdminPanel
                    sessionId={sessionId}
                    loading={loadingTickets}
                    setLoading={setLoadingTickets}
                  />
                }
              />
              <Route
                path="/admin/:id"
                element={
                  <TicketView
                    sessionId={sessionId}
                    setLoading={setLoadingTickets}
                  />
                }
              />
            </Route>
          </Routes>

          {location.pathname !== "/" ? <AppFooter /> : null}
        </Box>
      )}
    </>
  );
}

export default App;
