import React, { useState, useEffect } from "react";
import Ticket from "../component/ticket";
import axios from "axios";
import {
  Typography,
  Grid,
  Container,
  TextField,
  Button,
  Box,
} from "@mui/material";
import TicketTabs from "./tabs";
import SquareIcon from "@mui/icons-material/Square";
import { useAuth } from "../utils/UserContext";

export default function AdminPanel(props) {
  const [tickets, setTickets] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState();
  const [searchWord, setSearchWord] = useState("");
  const { logout, currentUser } = useAuth();

  const loading = props.loading;
  const setLoading = props.setLoading;

  useEffect(() => {
    axios
      .get(`/merge.php/?session_id=${props.sessionId}`)
      .then((response) => {
        setTickets(response.data);
        setFilteredTickets(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(
          "This is the error in AdminPanel Loading Tickets: " + error
        );
      });
    // setLoading(false);
  }, [loading]);

  function search() {
    const filtered = tickets.filter((ticket) => {
      if (
        ticket.ticketid &&
        ticket.ticketid.toString().includes(searchWord.toLowerCase())
      )
        return true;
      if (
        ticket.email &&
        ticket.email.toLowerCase().includes(searchWord.toLowerCase())
      )
        return true;

      if (
        ticket.name &&
        ticket.name.toLowerCase().includes(searchWord.toLowerCase())
      )
        return true;

      if (
        ticket.id &&
        ticket.id.toLowerCase().includes(searchWord.toLowerCase())
      )
        return true;

      if (
        ticket.company &&
        ticket.company.toLowerCase().includes(searchWord.toLowerCase())
      )
        return true;

      if (
        ticket.unit &&
        ticket.unit.toLowerCase().includes(searchWord.toLowerCase())
      )
        return true;

      if (
        ticket.feedback &&
        ticket.feedback.toLowerCase().includes(searchWord.toLowerCase())
      )
        return true;

      return false;
    });
    setFilteredTickets(filtered);
  }

  function reset() {
    setFilteredTickets(tickets);
  }

  return (
    <>
      <Container sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Grid container spacing={2} alignItems="center" justifyContent="end">
              <Grid item>
                <Typography>Welcome, {currentUser.email}</Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={logout}>
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <TextField
                  fullWidth
                  id="name"
                  size="small"
                  value={searchWord}
                  onChange={(e) => setSearchWord(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button onClick={search}>Search</Button>
                <Button onClick={reset}>Reset</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Typography component="div">
                {" "}
                <b>Positive Feedback</b>
              </Typography>
              <Box color="#b0ffc2">
                <SquareIcon color="inherit" />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Typography> Negative Feedback</Typography>
              <Box color="#ffb1b1">
                <SquareIcon color="inherit" />
              </Box>
            </Grid>
          </Grid>
          {loading ? null : (
            <Grid item>
              <TicketTabs
                sessionId={props.sessionId}
                setLoading={setLoading}
                tickets={filteredTickets}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
