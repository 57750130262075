import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function LanguageSelect(props) {
  const { i18n, t } = useTranslation();

  const location = useLocation();

  const labelSplash = {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  };

  const label = {
    color: "primary.main",
    "&.Mui-focused": {
      color: "primary.main",
    },
  };
  const stylesSplash = {
    select: {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        color: "white",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        borderWidth: "0.15rem",
        color: "white",
      },
      "&:after": {
        borderColor: "white",
      },
      "& .MuiOutlinedInputBase-root": {
        color: "white",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "&.Mui-focused": {
        color: "white",
      },
    },
  };

  const styles = {
    select: {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
        borderWidth: "0.15rem",
      },
    },
  };

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
    props.setLanguage(e.target.value);
  };

  return (
    <Box sx={{ minWidth: 120, maxWidth: 120, color: "white" }}>
      <FormControl fullWidth>
        {location.pathname === "/" ? (
          <>
            <InputLabel sx={labelSplash} id="demo-simple-select-label">
              {t("language")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.language}
              label={t("language")}
              onChange={handleChange}
              sx={stylesSplash.select}
              inputProps={{ sx: { color: "white" } }}
            >
              <MenuItem value={"es"}>ES</MenuItem>
              <MenuItem value={"en"}>EN</MenuItem>
              <MenuItem value={"pt"}>PT</MenuItem>
            </Select>
          </>
        ) : (
          <>
            <InputLabel sx={label} id="demo-simple-select-label">
              {t("language")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("language")}
              value={props.language}
              onChange={handleChange}
              sx={styles.select}
              inputProps={{ color: "white" }}
            >
              <MenuItem value={"es"}>ES</MenuItem>
              <MenuItem value={"en"}>EN</MenuItem>
              <MenuItem value={"pt"}>PT</MenuItem>
            </Select>
          </>
        )}
      </FormControl>
    </Box>
  );
}
