import { Button, Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../component/langSelect";
import HeaderImg from "../assets/images/thankyou.png";

export default function Thanks(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Grid item sx={{}}>
        <img
          width="100%"
          height="auto"
          src={HeaderImg}
          alt="Thank you Header"
        />
      </Grid>
      <Grid container justifyContent="end">
        <Grid item sx={{ mt: 4, mr: 2, mb: 2 }}>
          <LanguageSelect
            language={props.language}
            setLanguage={props.setLanguage}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center">
        <Grid item sx={{ mb: 4 }}>
          <Typography variant="h4"> {t("thanks")}</Typography>
        </Grid>
        <Grid item sx={{ mb: 10 }} >
          {props.posFeedback === true ? (
            <Typography align="center" variant="h5">{t("thanks_positiveFeedback")}</Typography>
          ) : (
            <Typography align="center" variant="h5">{t("thanks_negativeFeedback")}</Typography>
          )}
        </Grid>
        <Grid item>
          <Box color="white">
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                borderRadius: 8,
                backgroundColor: "#1B1534",
                mb: 2,
                width: "100%",
              }}
              onClick={() => {
                navigate("/survey/");
              }}
            >
              {t("take_another")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
