import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./firebaseConfig";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); 

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    setCurrentUser(null);
    return signOut(auth);
  }

  function setUser(user) {
    return setCurrentUser(user);
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
     setCurrentUser(user); 
     setLoading(false); 
    });
  }, []);


  const value = {
    currentUser,
    login,
    logout,
    setUser, 
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
