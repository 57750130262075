import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/UserContext";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState(""); 
  const { login } = useAuth();
  const navigate = useNavigate();
  
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleSubmit() {
    setMsg(""); 
    login(email, password)
      .then((user) => {
        navigate("/admin"); 
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(error);
        setMsg("There has been an issue."); 
        // ..
      });
  }

  return (
    <Box component="form" noValidate autoComplete="off" padding={3}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Typography>Email</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                id="email"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Typography>Password</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <OutlinedInput
                id="outlined-adornment-password"
                fullWidth
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
            <Typography color="red">
                {msg}
            </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={handleSubmit}>Login</Button>
        </Grid>
        {/* <Grid item>
        <Link color="#1B1534" href={"/register/"}>
                {"Don't have an account yet?"}
              </Link>
        </Grid> */}
        
      </Grid>
    </Box>
  );
}
