import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Grid, Box } from "@mui/material";
import { useAuth } from "../utils/UserContext";
import axios from "axios";
import AssignDialog from "../component/assignDialog";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import UnassignDialog from "../component/unassignConfirmDialog";
import { useParams } from "react-router-dom";
import Link from "@mui/material/Link";

const admins = [
  {
    firstName: "Fadi",
    lastName: "Abou Ghantous",
    email: "fadi.ghantous@siemens-energy.com",
  },
  {
    firstName: "Brenda",
    lastName: "Mendez",
    email: "brenda.mendez@siemens-energy.com",
  },
  {
    firstName: "Grace",
    lastName: "Alvarez",
    email: "grace.alvarez@siemens-energy.com",
  },
  {
    firstName: "Mike",
    lastName: "Klinkhammer",
    email: "mike.klinkhammer@siemens-energy.com",
  },
  {
    firstName: "Joe",
    lastName: "Matos",
    email: "joe.matos@siemens-energy.com",
  },
  {
    firstName: "Alejandro",
    lastName: "Herrera",
    email: "alejandro.herrera@siemens-energy.com",
  },
  {
    firstName: "Ferdinand",
    lastName: "Velez",
    email: "ferdinand.velez@siemens-energy.com",
  },
  {
    firstName: " Joseph",
    lastName: "Dodd Rodriguez",
    email: "joseph.dodd@siemens-energy.com",
  },
  {
    firstName: " Ute",
    lastName: "Baier",
    email: "ute.baier@siemens-energy.com",
  },
  {
    firstName: "Alejandro",
    lastName: "Neira",
    email: "alejandro.neira@siemens-energy.com",
  },
  {
    firstName: "Christine",
    lastName: "Stevens",
    email: "christine.stevens.ext@siemens-energy.com",
  },
];

export default function TicketView(props) {
  const { currentUser } = useAuth();
  const [ticket, setTicket] = React.useState(null);
  const [loadingTicket, setLoadingTicket] = React.useState(true);

  const { id } = useParams();

  const color =
    ticket &&
    (ticket.positive === "true" || ticket.positive === true
      ? "#b0ffc2"
      : "#ffb1b1");

  function getTicket() {
    axios
      .get(`/getTicket.php/?session_id=${props.sessionId}&ticket_id=${id}`)
      .then(function (response) {
        setTicket(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  React.useEffect(() => {
    getTicket();
    setLoadingTicket(false);
  }, []);

  function getAdmin(email) {
    return admins.find((obj) => {
      return obj.email === email;
    });
  }

  function marks() {
    if (ticket.resolving) {
      const adminInfo = getAdmin(ticket.admin_name.replace(" ", "@"));
      const adminName = adminInfo["firstName"] + " " + adminInfo["lastName"];

      return (
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <UnassignDialog
              sessionId={props.sessionId}
              setLoading={props.setLoading}
              ticket={ticket}
              currentUser={currentUser}
            ></UnassignDialog>
          </Grid>
          <Grid item>
            <Typography variant="subtitle" color="text.secondary">
              Assigned to {adminName} on {ticket.time_updated}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <AssignDialog
          sessionId={props.sessionId}
          setLoading={props.setLoading}
          ticket={ticket}
          currentUser={currentUser}
        />
      );
    }
  }

  return (
    <>
      {!loadingTicket &&
      ticket &&
      (ticket.positive != null || ticket.positive != undefined) ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent={"space-around"}
          padding={5}
        >
          <Grid item>
            <Typography variant="h5"> Ticket #{ticket.ticketid}</Typography>
          </Grid>
          <Card sx={{ minWidth: "300px", backgroundColor: color }}>
            <CardContent>
              <Grid container direction="row" justifyContent={"space-between"}>
                <Grid item xs={12} sm={6}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body2">Name:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {ticket.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body2">Company:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {ticket.company}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body2">Unit:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {ticket.unit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body2">Email:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {ticket.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body2">Ticket Created:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {ticket.time_created}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} sx={{ mb: 2 }}>
                    <Grid item>
                      <Typography variant="body2">Feedback:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {ticket.feedback}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography variant="body2">Ticket ID:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {ticket.ticketid}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Typography sx={{ mr: 1 }} variant="body2">
                      Feedback type:
                    </Typography>
                    {ticket.positive === true || ticket.positive === "true" ? (
                      <SentimentSatisfiedAltIcon />
                    ) : (
                      <SentimentVeryDissatisfiedIcon />
                    )}
                  </Grid>
                  {ticket.resolving ? (
                    <Typography variant="body2">In Progress</Typography>
                  ) : (
                    <Typography variant="body2">Open</Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions disableSpacing>{marks()}</CardActions>
          </Card>
          <Grid item>
            <Link color="#1B1534" href="/admin">
              <Typography variant="caption">Return to Admin Panel</Typography>
            </Link>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent={"space-around"}
          padding={5}
        >
          <Grid item>
            <Typography> There was a problem loading the Ticket. </Typography>
          </Grid>
          <Grid item>
            <Link color="#1B1534" href="/admin">
              <Typography variant="caption">Return to Admin Panel</Typography>
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  );
}
