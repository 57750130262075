import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MenuItem, Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const admins = [
  {
    firstName: "Fadi",
    lastName: "Abou Ghantous",
    email: "fadi.ghantous@siemens-energy.com",
  },
  {
    firstName: "Brenda",
    lastName: "Mendez",
    email: "brenda.mendez@siemens-energy.com",
  },
  {
    firstName: "Grace",
    lastName: "Alvarez",
    email: "grace.alvarez@siemens-energy.com",
  },
  {
    firstName: "Mike",
    lastName: "Klinkhammer",
    email: "mike.klinkhammer@siemens-energy.com",
  },
  {
    firstName: "Joe",
    lastName: "Matos",
    email: "joe.matos@siemens-energy.com",
  },
  {
    firstName: "Alejandro",
    lastName: "Herrera",
    email: "alejandro.herrera@siemens-energy.com",
  },
  {
    firstName: "Ferdinand",
    lastName: "Velez",
    email: "ferdinand.velez@siemens-energy.com",
  },
  {
    firstName: " Joseph",
    lastName: "Dodd Rodriguez",
    email: "joseph.dodd@siemens-energy.com",
  },
  {
    firstName: " Ute",
    lastName: "Baier",
    email: "ute.baier@siemens-energy.com",
  },
  {
    firstName: "Alejandro",
    lastName: "Neira",
    email: "alejandro.neira@siemens-energy.com",
  },
  {
    firstName: "Christine",
    lastName: "Stevens",
    email: "christine.stevens.ext@siemens-energy.com",
  },
];

export default function AssignDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("claim");

  const [assignedAdmin, setAssignedAdmin] = React.useState("");
  const navigate = useNavigate();

  const currentUser = props.currentUser;
  const ticket = props.ticket;

  function getAdmin(email) {
    return admins.find((obj) => {
      return obj.email === email;
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleMark();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  function sendEmail(assigner, assignee, email, ticketid) {
    const formData = new FormData();

    formData.append("assigner", assigner);
    formData.append("assignee", assignee);
    formData.append("email", email);
    formData.append("ticketid", ticketid);

    axios
      .post(`ticketmail.php?session_id=${props.sessionId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // console.log(response);
      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        console.error(error);
      });
  }

  function handleMark() {
    let adminEmail;
    if (value === "claim") {
      adminEmail = currentUser.email;
    } else {
      adminEmail = assignedAdmin;
    }

    const adminInfo = getAdmin(adminEmail);
    const adminName = adminInfo["firstName"] + " " + adminInfo["lastName"];

    const objToSend = {
      admin_name: adminEmail,
      resolving: true,
      assigned_by: currentUser.email,
    };

    props.setLoading(true);

    axios
      .put(
        `editAdminStatus.php?session_id=${props.sessionId}&ticket_id=${ticket.ticketid}`,
        objToSend
      )
      .then(function (response) {
        sendEmail(currentUser.email, adminName, adminEmail, ticket.ticketid);
        props.setLoading(false);
        navigate(`/admin/${ticket.ticketid}`);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Claim/Assign
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Assign Ticket</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you claiming this ticket or assigning it to someone else?
          </DialogContentText>
          <Grid container>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="claim"
                  control={<Radio />}
                  label="Claim"
                />
                <FormControlLabel
                  value="assign"
                  control={<Radio />}
                  label="Assign"
                />
              </RadioGroup>
            </FormControl>
            <Grid item xs={12}>
              {value === "claim" ? null : (
                <TextField
                  select
                  fullWidth
                  id="name"
                  value={assignedAdmin}
                  variant="standard"
                  onChange={(e) => {
                    // console.log(e.target.value)
                    setAssignedAdmin(e.target.value);
                  }}
                >
                  {admins
                    .filter((admin) => admin.email != currentUser.email)
                    .map((admin) => (
                      <MenuItem key={admin.email} value={admin.email}>
                        {admin.firstName} {admin.lastName}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleClose}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
