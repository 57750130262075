import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAuth } from "../utils/UserContext";
import Ticket from "../component/ticket";
import { Container, Grid } from "@mui/material";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TicketTabs(props) {
  const [value, setValue] = useState(0);

  const { currentUser } = useAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function claimed() {
    if (props.tickets) {

      if (props.tickets.length === 0) {
        return (
          <Container sx={{ py: 8 }}>
             <Grid container width="100%" spacing={4}>
              <Typography>
                There are no tickets. 
              </Typography>
             </Grid>
          </Container>
        )
      }
      return (
        <Container sx={{ py: 8 }}>
          <Grid container width="100%" spacing={4}>
            {props.tickets.map((ticket) => {
              if (ticket.admin_name === currentUser.email.replace("@", " ")) {
                return (
                  <React.Fragment key={ticket.response_id}>
                    <Grid item xs={12} sm={6}>
                      <Ticket sessionId={props.sessionId} ticket={ticket} setLoading={props.setLoading} />
                    </Grid>
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </Container>
      );
    }
  }

  function unclaimed() {

    if (props.tickets) {

      if (props.tickets.length === 0) {
        return (
          <Container sx={{ py: 8 }}>
             <Grid container width="100%" spacing={4}>
              <Typography>
                There are no tickets. 
              </Typography>
             </Grid>
          </Container>
        )
      }

      return (
        <Container sx={{ py: 8 }}>
          <Grid container width="100%" spacing={4}>
            {props.tickets.map((ticket) => {
              if (!ticket.admin_name || ticket.admin_name === "") {
                return (
                  <React.Fragment key={ticket.response_id}>
                    <Grid item xs={12} sm={6}>
                      <Ticket sessionId={props.sessionId} ticket={ticket} setLoading={props.setLoading} />
                    </Grid>
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </Container>
      );
    }
  }

  function all() {
    if (props.tickets) {

      if (props.tickets.length === 0) {
        return (
          <Container sx={{ py: 8 }}>
             <Grid container width="100%" spacing={4}>
              <Typography>
                There are no tickets. 
              </Typography>
             </Grid>
          </Container>
        )
      }

      return (
        <Container sx={{ py: 8 }}>
          <Grid container width="100%" spacing={4}>
            {props.tickets.map((ticket) => {
              return (
                <React.Fragment key={ticket.response_id}>
                  <Grid item xs={12} sm={6}>
                    <Ticket sessionId={props.sessionId} ticket={ticket} setLoading={props.setLoading} />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Container>
      );
    }
  }

  const ticketInfo = {
    "Query": "check_session_id",
    "response": "OK",
    "name": "Alejandro Neira",
    "email": "alejandro.neira@siemens-energy.com",
    "company": "Siemens Energy",
    "unit": "SGT50",
    "language": "es",
    "positive": "true",
    "feedback": "span positive",
    "AdminName": "",
    "resolving": true,
    "subject": "[e-CARLA SE]: alejandro.neira@siemens-energy.com - Information received",
    "email_body": "Estamos muy agradecidos de que se haya tomado el tiempo de enviarnos sus comentarios. Su experiencia positiva refleja nuestra prioridad de satisfacer a nuestros clientes. Valoramos profundamente sus comentarios y esperamos tener noticias suyas en el futuro.\r\n\r\nSaludos cordiales, \nGas Services, Latin America, Executive Team, Siemens Energy\r\n\r\nEn la fecha, 2023-01-24 11:40:36 usted nos envió la siguiente informacion a Siemens Energy a través de e-CARLA: \r\n- Nombre y Apellido: Alejandro Neira\r\n- E-mail: alejandro.neira@siemens-energy.com\r\n- Empresa: Siemens Energy\r\n- Unidad/Frame: SGT50\r\n- Comentarios: span positive\r\n",
    "admin_name": "employee se.com",
    "assignee": "employee se.com",
    "time_updated": "2023-01-30T21 59 48.127Z",
    "id": "03287fcce194dbd958c2ec5b33705912BUJpiz"
}; 

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      {/* <Ticket ticket={ticketInfo} setLoading={props.setLoading} /> */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Unclaimed" {...a11yProps(0)} />
          <Tab label="Assigned to You" {...a11yProps(1)} />
          <Tab label="All" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {unclaimed()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {claimed()}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {all()}
      </TabPanel>
    </Box>
  );
}
